import type { DataTableSortState } from 'naive-ui'
import type { PrismaKeys, PrismaOrderBy } from '~/types'

export default <K extends PrismaKeys>(sorter: DataTableSortState) => {
  let order: 'asc' | 'desc' | undefined
  if (sorter.order) {
    order = sorter.order === 'ascend' ? 'asc' : 'desc'
  }
  const orderBy: PrismaOrderBy<K>[] = []

  sorter.columnKey?.toString().split('+').forEach((pointer) => {
    const keys = pointer.split('.')

    const buildNestedObject = (keys: string[], order: 'asc' | 'desc'): PrismaOrderBy<K> => {
      if (keys.length === 1) {
        return { [keys[0]]: order }
      }
      const [firstKey, ...restKeys] = keys
      return { [firstKey]: buildNestedObject(restKeys, order) }
    }

    if (order) {
      return orderBy.push(buildNestedObject(keys, order))
    }
  })

  return orderBy.length ? orderBy : undefined
}
